.slider-div {
  padding-top: 95px;
}

.background-image-div {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 450px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: #355fb3 !important;
    color: #fff !important;
    box-shadow: none !important;
}

.slick-dots {
  top: 390px;
  position: absolute;
}

.leaflet-popup-tip-container {
    opacity: .8 !important;
    margin-top: -8px !important;
}

.veranstaltung-karte {
    padding-top: 56px;
    overflow: hidden;
    transition: all .5s;
}

.city-select-dropdown-home > div {
    max-width: 100%;
    width: 100%;
}

.city-select-dropdown {
    text-align: left;
}

.city-select-dropdown span {
  opacity: 0;
}

.city-select-dropdown.veranstaltungen svg {
  opacity: 0;
  margin-right: -12px;
}

.city-select-dropdown.veranstaltungen {
    pointer-events: none;
    cursor: default;
}

.city-select-dropdown.versorgungspartner {
    width: 512px;
}

/* Media Queries */

@media only screen and (min-width: 1600px) {
  .div-one,
  .div-two,
  .div-three {
    height: 500px;
  }
}

@media only screen and (max-width: 1194px) {

.veranstaltung-karte {
    padding-top:  0px !important;
    overflow: hidden;
    transition: all .5s;
}
}