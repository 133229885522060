.footer-wrap {
  border-top: 10px solid #f8f8f8;
  height: 320px;
}

.footer-wrapper {
  display: flex;
  width: 81%;
  margin: 0 auto;
  justify-content: space-between;
  color: #545454;
  font-size: 13px;
  padding-top: 50px;
}

.i-button:hover {
    background: #597ec7;
}

.i-button{
    border: 1px solid #ffffff;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    margin: 6px 22px 0 22px;
    font-weight: 800;
    padding: 6px 14px;
    text-align: center;
    line-height: 23px;
    height: 22px;
    cursor: pointer;
}
.i-button--content {
    line-height: 32px;
    font-size: 18px;
}

.footer-wrapper ul {
  list-style-type: none;
  padding-top: 12px;
}

.footer-wrapper ul li {
  padding-top: 2px;
  height: 27px;
  text-align: left;
  cursor: pointer;
  color: #6a6a6a;
}

.footer-wrapper ul li:hover {
  color: #355fb3;
}

.column-one {
  width: 345px;
  text-align: left;
}

.column-one img {
  height: 25px;
  width: 150px;
}

h3 {
  margin-top: 0;
  text-align: left;
  padding-left: 38px;
}

.footer-wrap h3 {
  padding-left: 0px;
}

.column-two,
.column-three {
  flex: 1;
}

.column-four {
  width: 345px;
}

.column-four p {
  text-align: left;
  padding-left: 37px;
  color: #6a6a6a;
}

.social-media-contact {
  text-align: left;
  padding-left: 0px;
}

.social-connect {
  width: 30px;
  padding-right: 7px;
  cursor: pointer;
}

/* Media Queries */

@media only screen and (min-width: 1600px) {
  .footer-wrapper {
    width: 65%;
  }
}
.referenten full-desc {
    margin-left: 30%;
}
.full-desc {
  text-align: left;
    font-size: 22px;
    margin-top: 38px;
}

.pseudoTable thead, .pseudoTable .filterDiv, .pseudoTable .pagination {
    display: none;
}

.table tbody > tr:hover {
    background: #355fb3;
    font-weight: 800;
    cursor: pointer;
    color: white;
}

.footer-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.column-one,
.column-two,
.column-three,
.column-four {
  flex: 1;
  margin: 10px; /* Adjust margin as needed */
}
@media (max-width: 962px) {
    .contentWrap {
        /*padding-top: 50px*/
    }
}

.referent {
    margin-top: -24px;
}

@media (max-width: 1094px) {
.ant-col-xs-18 {
    max-width: 100%;
}

.referent {
    display: inline-block !important;
}

.leafContainer--produkt .leaf--value {
    float: right;
}

.referent .leaf--value {
    display: block !important;
}

.in-news {
    display: block !important;
}
  .footer-wrapper {
    flex-direction: column;
   padding-bottom: 4rem;
  }

  .column-one {
   padding-bottom: 2rem;
  }

.headout-picks-nav h1 {
    white-space: nowrap;
}

.view-all h3 {
    display: none;
}


.media-wrapper {
   display: flex;
   margin-bottom: 2rem;
}

.media-wrapper hr {
    display: none;
}

    .news-box {
        width: 80% !important;
        float: left;
    }

    .cards-wrapper {
        margin-top: 2rem  !important;
        display: inline-block  !important;
        width: 70%  !important;
    }

    .top-veranstaltungen-carousel-wrap, .top-picked-carousel-wrap {
        width: 100% !important;
    }

    .search-bar-div {
        display: inline-grid !important;
    }

    .select-city-large {
        margin-left: 5px !important;
    }

    .select-experience-large input {
        max-width: 80% !important;
    }

    .select-experience-large {
        max-width: 90% !important;
    }

    .iwnav-channels a:first-child {
        display: none !important;
    }


    .leafContainer {
        border-radius: 26px;
        height: 100% !important;
        padding: 1em !important;
    }

    .leafImage {
      padding-bottom: 28px;
    }

    .leafImage--veranstaltung {
        max-width: 100% !important;
    }

    .leaf--title {
        width: 100% !important;
        display: inline-block !important;
        padding-bottom: 12px !important;
    }

    .referent .leaf--p, .referent .leaf--value {
        width: 100% !important;
    }

    .leafImage--produkt {
        height: auto !important;
        max-width: 100% !important;
        max-height: 100% !important;
        margin: 0 auto !important;
        width: 100% !important;
    }

    .referent .leaf--value {
         width: 100% !important;
         height: auto !important;
         overflow: initial !important;
     }

     .leafContainer--referent {
        margin: 0 auto !important;
     }

     .leafImage--referent {
        width: 100% !important;
        margin: 0 !important;
     }
}

.ant-upload-list-item-actions {
    margin-left: 48px;
}

.site-card-wrapper > div > .ant-row:nth-child(even) {
    background-color: #3545b3; /* Light gray */
    color: white;
    padding: 2em;
    margin-bottom: 6px;
 }
.site-card-wrapper > div > .ant-row:nth-child(even) label {
    color: white;
 }

.site-card-wrapper > div > .ant-row:nth-child(odd) label {
    color: white;
 }
.site-card-wrapper > div > .ant-row:nth-child(odd) {
   background-color: #355fb3; /* Light gray */
   padding: 2em;
}

.ant-select-disabled {
    opacity: .3;
}

.edLimited {
    padding: 8px 64px 28px 16px;
    max-height: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edDrop {
    padding: 8px 64px 28px 16px;
}
.edDrop p {

    margin-block-start: .2em;
        margin-block-end: .2em;

}

.oneLine {
    height: 75px;
    width: 214px;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}