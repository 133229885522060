.new-york-wrapper {
  text-align: center;
}

.travel-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0px 20px;
}

.city-section-wrapper {
  width: 85%;
  margin: 0px auto;
  padding-top: 16px;
}
.produktliste {
    padding: 1em;
}
.produktliste .leaf--value > a {
  color: white !important;
}
/* Media Queries */
#hersteller-products .headout-picks-wrapper {
    margin-left: 0;
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .city-section-wrapper {
    width: 80%;
    padding: 0px 10px;
  }
}

.static-padding {
    padding-top: 46px;
    padding-bottom: 46px;
}

@media only screen and (min-width: 1200px) and (max-width: 1380px) {
  .city-section-wrapper {
    width: 85%;
    padding-left: 50px;
  }
}

@media only screen and (min-width: 1600px) {
  .city-section-wrapper {
    width: 70%;
    margin: 0 auto;
    padding-left: 150px;
  }
}

.leaflet-container {
  height: 512px;
  width: 100%;
}

.table td {
    height: 48px;
    font-size: 18px;
    vertical-align: middle;
}

.table th {
  cursor: pointer;
  text-align: left !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.table .empty-cell {
  font-style: italic;
  color: darkgray;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.sort-icon:after {
  float: right;
  color: hsl(20, 0%, 70%);
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sort-icon.sort-ascending:after  { content: '\f0de'; }
.sort-icon.sort-descending:after { content: '\f0dd'; }
.sort-icon.sort-none:after  { content: '\f0dc'; }
